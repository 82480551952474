<div
   #tableHeaderSearch
   class="disabled">
   <div class="table-header-search">
      <nz-input-group
         nzSearch
         [nzAddOnAfter]="suffixIconButton">
         <input
            type="text"
            nz-input
            (ngModelChange)="search()"
            [(ngModel)]="idSearchText"
            placeholder="Search IDs here..." />
      </nz-input-group>
      <ng-template #suffixIconButton>
         <button
            (click)="searchId()"
            nz-button
            nzSearch>
            <span
               nz-icon
               nzType="search"></span>
         </button>
      </ng-template>
   </div>
</div>

<div #fileIngestTable>
   <nz-table
      nzBordered
      class="related-content-table"
      [ngClass]="{ 'no-content-table': relatedContentData.length == 0 }"
      [nzData]="relatedContentData"
      [nzFrontPagination]="false"
      [nzTotal]="tableParams.total"
      [nzPageIndex]="tableParams.page"
      [nzPageSize]="tableParams.limit"
      nzShowSizeChanger
      [nzLoading]="!isLoaded"
      [nzLoadingDelay]="750"
      (nzPageIndexChange)="onChangePage($event)"
      (nzPageSizeChange)="onChangePageSize($event)"
      [nzScroll]="{ y: tableHeight + 'px' }"
      nzShowQuickJumper>
      <thead #tableHeader>
         <tr class="disabled">
            <th
               [nzWidth]="preferenceColumns['IDSTATUS'].width + 'px'"
               [nzFilters]="statusFilters"
               [nzFilterFn]="true"
               (nzFilterChange)="statusFilterEvent($event)">
               {{ preferenceColumns['IDSTATUS'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="idSearchVisible"
                  [nzActive]="idSearchText.length > 0"
                  [nzDropdownMenu]="idTemplate">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['DESTINATION'].width + 'px'"
               nzCustomFilter>
               {{ preferenceColumns['DESTINATION'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="destinationSearchVisible"
                  [nzActive]="destinationSearchText.length > 0"
                  [nzDropdownMenu]="destinationTemplate"
                  (nzVisibleChange)="destinationVisibleChange(destinationSearchVisible)">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['REQUESTER'].width + 'px'"
               nzCustomFilter>
               {{ preferenceColumns['REQUESTER'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="requesterSearchVisible"
                  [nzActive]="requesterSearchText.length > 0"
                  [nzDropdownMenu]="requesterTemplate"
                  (nzVisibleChange)="requesterVisibleChange(requesterSearchVisible)">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['SUBMITTED'].width + 'px'"
               [nzLeft]="false"
               [rowspan]="preferenceColumns['SUBMITTED'].rowSpan"
               [nzSortFn]="true"
               [nzSortOrder]="submittedDateSortOrder"
               (nzSortOrderChange)="submittedDateSortEvent($event)">
               {{ preferenceColumns['SUBMITTED'].text }}
            </th>

            <th
               [nzWidth]="preferenceColumns['MODIFIED'].width + 'px'"
               [nzLeft]="false"
               [rowspan]="preferenceColumns['MODIFIED'].rowSpan"
               [nzSortFn]="true"
               [nzSortOrder]="modifiedDateSortOrder"
               (nzSortOrderChange)="modifiedDateSortEvent($event)">
               {{ preferenceColumns['MODIFIED'].text }}
            </th>

            <th
               [nzWidth]="preferenceColumns['SHOWPROJECT'].width + 'px'"
               [rowspan]="preferenceColumns['SHOWPROJECT'].rowSpan"
               nzCustomFilter>
               {{ preferenceColumns['SHOWPROJECT'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="showOrProjectSearchVisible"
                  [nzActive]="showOrProjectSearchText.length > 0"
                  [nzDropdownMenu]="showOrProjectTemplate"
                  (nzVisibleChange)="ShowOrProjectVisibleChange(showOrProjectSearchVisible)">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['AIRDATE'].width + 'px'"
               [nzLeft]="true"
               [rowspan]="preferenceColumns['AIRDATE'].rowSpan"
               [nzSortFn]="true"
               [nzSortOrder]="airDateSortOrder"
               (nzSortOrderChange)="airDateSortEvent($event)">
               {{ preferenceColumns['AIRDATE'].text }}
            </th>
         </tr>
      </thead>
      <tbody>
         <ng-container *ngFor="let data of relatedContentData; let i = index">
            <tr>
               <td>
                  <a
                     class="flex"
                     [ngStyle]="{ color: requestStatusDetails[data.content.statusIndex]?.color }"
                     (click)="openApp(data.content.zrouterlink, 'producer-dashboard')"
                     [href]="data.content.zrouterlink">
                     <span
                        [style.color]="requestStatusDetails[data.content.statusIndex]?.color"
                        [ngClass]="requestStatusDetails[data.content.statusIndex]?.class">
                        <span
                           nz-icon
                           class="status-icon"
                           [nzType]="requestStatusDetails[data.content.statusIndex]?.icon"
                           [nzTheme]="
                              requestStatusDetails[data.content.statusIndex]?.icon === 'sync' ? 'outline' : 'fill'
                           "></span>
                        <span
                           nz-tooltip
                           nzTooltipPlacement="bottomLeft"
                           nzTooltipColor="white">
                           {{ data.content.zid }}
                        </span>
                     </span>
                     <span
                        nz-icon
                        class="id-right-icon"
                        nzType="right"
                        nzTheme="outline"></span>
                  </a>
               </td>

               <td [nzEllipsis]="true">
                  <!-- <span>
                     <span *ngIf="!data.content.zDestinationsRemaining">
                        {{ data.content.zDestinationsDisplayString }}
                     </span>

                     <span
                        *ngIf="data.content.zDestinationsRemaining && data.content.zDestinationsRemainingLength === ''"
                        nz-popover
                        [nzPopoverTitle]="titleTemplate"
                        [nzPopoverContent]="DestinationsDataTemplate">
                        {{ data.content.zDestinationsDisplayString }}
                     </span>

                     <span
                        class="flexbox"
                        *ngIf="data.content.zDestinationsRemaining && data.content.zDestinationsRemainingLength !== ''">
                        {{ data.content.zDestinationsDisplayString }}
                        <span
                           class="additional-records"
                           nz-popover
                           [nzPopoverTitle]="titleTemplate"
                           [nzPopoverContent]="DestinationsDataTemplate">
                           {{ data.content.zDestinationsRemainingLength }}
                        </span>
                     </span> -->

                  <span
                     nz-popover
                     nzPopoverPlacement="left"
                     [nzPopoverTitle]="titleTemplate"
                     [nzPopoverContent]="DestinationsDataTemplate">
                     {{ data.content.zDestinationsDisplayString }}

                     <ng-template #titleTemplate>
                        <span class="popover-title">Destination</span>
                     </ng-template>

                     <ng-template #DestinationsDataTemplate>
                        <div class="popover-content">
                           <ul class="list-items">
                              <ng-container *ngFor="let destination of data.content.zDestinationsDisplayList">
                                 <li class="">{{ destination }}</li>
                              </ng-container>
                           </ul>
                        </div>
                     </ng-template>
                  </span>
               </td>

               <td [nzEllipsis]="true">
                  <!-- <span
                     *ngIf="data.content.zRequestersRemaining"
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.content.zRequestersDisplayList">
                     {{ data.content.zRequestersDisplayString }}
                  </span>

                  <span *ngIf="!data.content.zRequestersRemaining">{{ data.content.zRequestersDisplayString }}</span> -->

                  <span
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.content.requestorName">
                     {{ data.content.requestorName }}
                  </span>
               </td>

               <td>{{ data.content.requestCreated | date: 'MM/dd/yyyy (hh:mm a)' }}</td>

               <td>{{ data.content.requestModified | date: 'MM/dd/yyyy (hh:mm a)' }}</td>

               <td [nzEllipsis]="true">
                  <!-- <span
                     *ngIf="data.content.zShowUnitsRemaining"
                     nz-popover
                     [nzPopoverTitle]="showUnitTitleTemplate"
                     [nzPopoverContent]="showUnitDataTemplate">
                     {{ data.content.zShowUnitsDisplayString }}
                  </span>

                  <span *ngIf="!data.content.zShowUnitsRemaining">{{ data.content.zShowUnitsDisplayString }}</span> -->

                  <span
                     nz-popover
                     nzPopoverPlacement="topLeft"
                     [nzPopoverTitle]="showUnitTitleTemplate"
                     [nzPopoverContent]="showUnitDataTemplate">
                     {{ data.content.zShowUnitsDisplayString }}

                     <ng-template #showUnitTitleTemplate>
                        <span class="popover-title">Show / Project</span>
                     </ng-template>

                     <ng-template #showUnitDataTemplate>
                        <div class="popover-content">
                           <ul class="list-items">
                              <ng-container *ngFor="let show of data.content.zShowUnitsDisplayList">
                                 <li class="">{{ show }}</li>
                              </ng-container>
                           </ul>
                        </div>
                     </ng-template>
                  </span>
               </td>

               <td class="date-right-align">
                  {{
                     data.content.airDateTBD == 'Yes'
                        ? 'TBD'
                        : data.content.airDate == 0
                          ? ''
                          : (data.content.requestAirDate | date: 'MM/dd/yyyy')
                  }}
               </td>
            </tr>
         </ng-container>
      </tbody>
   </nz-table>
</div>

<!-- GUEST COLUMN SEARCH -->
<nz-dropdown-menu
   #idTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Id(s)"
            (keyup.enter)="searchId()"
            [(ngModel)]="idSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchId()"
            class="search-button"
            [ngClass]="idSearchText != '' ? 'search-reset-button-on-input' : ''">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetIdSearch()"
            [ngClass]="idSearchText != '' ? 'search-reset-button-on-input' : ''">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #requesterTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Requester(s)"
            (keyup.enter)="searchRequester()"
            [(ngModel)]="requesterSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchRequester()"
            class="search-button"
            [ngClass]="requesterSearchText != '' ? 'search-reset-button-on-input' : ''">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetRequesterSearch()"
            [ngClass]="requesterSearchText != '' ? 'search-reset-button-on-input' : ''">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #showOrProjectTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Show / Project"
            (keyup.enter)="searchShowOrProject()"
            [(ngModel)]="showOrProjectSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchShowOrProject()"
            class="search-button"
            [ngClass]="showOrProjectSearchText != '' ? 'search-reset-button-on-input' : ''">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetShowOrProjectSearch()"
            [ngClass]="showOrProjectSearchText != '' ? 'search-reset-button-on-input' : ''">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #destinationTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Destination"
            (keyup.enter)="searchDestination()"
            [(ngModel)]="destinationSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchDestination()"
            class="search-button"
            [ngClass]="destinationSearchText != '' ? 'search-reset-button-on-input' : ''">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetDestinationSearch()"
            [ngClass]="destinationSearchText != '' ? 'search-reset-button-on-input' : ''">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-modal
   [(nzVisible)]="showTempModal"
   [nzNoAnimation]="true"
   [nzClosable]="false"
   [nzFooter]="null"
   [nzCentered]="true">
   <ng-template #modalTitle></ng-template>
   <div class="modal-content">
      <ng-container *nzModalContent>
         <div class="content">
            <div class="icon-container">
               <span
                  class="icon"
                  nz-icon
                  nzType="notification"
                  nzTheme="outline"></span>
            </div>
            <div>
               <div class="content-header">Coming Soon!</div>
               <div class="content-text">
                  You'll soon be able to view, search and navigate to related Producer Dashboard Crew/Edit/Feed Out/File
                  Ingest requests here.
               </div>
               <div class="close-modal">
                  <button
                     class="modal-close"
                     nz-button
                     nzType="primary"
                     (click)="closeModal()">
                     Ok
                  </button>
               </div>
            </div>
         </div>
      </ng-container>
   </div>
</nz-modal>
