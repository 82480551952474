import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Time } from '@utilities/time';

@Component({

  // tslint:disable-next-line: component-selector
  selector: 'app-post-versions',
  templateUrl: './post-versions.component.html',
  styleUrls: ['./post-versions.component.scss']
})
export class PostVersionsComponent implements OnInit {

  postVersions: any;

  postAPI: string;

  postVersionGroupId: any;

  expandedId: any;

  isLoaded = false;

  private parentUrl: string;

  constructor(
    private cService: CommonService,
    private location: Location,
    private router: ActivatedRoute,
    private reRoute: Router,
    private toastService: ToastService
  ) {

    /** Nothing to do */
  }

  // 991 to 768 : no need , 576 to 767 : timestamp, 300px 575px : createdby and timestamp
  expanded = false;

  itemName = [];

  mapOfExpandData: { [key: string]: boolean } = {};

  ngOnInit() {

    this.isLoaded = false;
    this.postAPI = environment.getPostApi;
    this.router.params.subscribe(
      (params: Params) => {

        this.setParams(params);

      });

  }

  setParams(params) {

    if (params && params.postVersionGroupId) {

      const id = params.postVersionGroupId;

      this.postVersionGroupId = id.substr(1);
      this.getVersions(this.postVersionGroupId);

    } else {

      this.isLoaded = true;

    }

  }

  showMoreData(val, versionNo) {

    this.expanded = !this.expanded;
    this.expandedId = versionNo;
    this.itemName = val;

  }

  formatDataGrid() {

    this.itemName = [];
    if (window.innerWidth >= 1200) {

      return false;

      // return (index < (length - 1)) ? true : false;

    } else if (window.innerWidth >= 576 && window.innerWidth <= 767) {

      this.itemName.push('timestamp');
      return true;

      // return (index < (length - 2)) ? true : false;

    } else if (window.innerWidth >= 300 && window.innerWidth <= 575) {

      this.itemName.push('changedBy');
      this.itemName.push('timestamp');
      return true;

      // return (index < (length - 2)) ? true : false;

    } else {

      return true;

    }

  }

  openPost(postId) {

    this.reRoute.navigate(['ncx/view-post/:' + postId]);

  }

  back() {


    // console.log('back buttom clicked',this.postVersions);
    // const livePost = this.postVersions.filter((post) => post.postVersionState === 'Live')[0];
    // if (livePost && livePost.postId) {
    //  this.reRoute.navigate(['ncx/view-post/:' + livePost.postId]);
    //  }
    //  else{
    this.location.back();

    //  }

  }

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);

    } else {

      return Time.convertingUtcToLocalTime(apiDate);

    }

  }

  removePostVersionFromList(postId) {

    const updateArray = [];

    this.postVersions.forEach(post => {

      if (post.postId !== postId) {

        updateArray.push(post);

      }

    });
    this.postVersions = updateArray;

  }

  /* API's to fetch Version Details */
  getVersions(postVersionGroupId) {

    const getVersionsURL = `${this.postAPI}/${postVersionGroupId}/version`;

    this.cService.serviceRequestCommon('get', getVersionsURL).subscribe((data: any) => {

      console.log('Success : ', data);
      this.postVersions = data.posts;
      this.isLoaded = true;

    }, (err) => {

      console.log('Error : ', err);
      this.isLoaded = true;

    });

  }

  createVersion(data) {

    this.isLoaded = false;
    const getVersionsURL = `${this.postAPI}/version`;

    const payload = JSON.parse(JSON.stringify(data));


    // payload.postId = null;
    payload.isPostPublished = false
    payload.postVersionState = 'Draft';
    console.log('createVersion ', data);
    this.cService.serviceRequestCommon('post', this.postAPI, '', payload).subscribe((res: any) => {

      console.log('Success createVersion : ', res);
      this.isLoaded = true;
      this.reRoute.navigate(['ncx/view-post/:' + res.postId]);

    }, (err) => {

      this.isLoaded = true;
      console.log('Error createVersion : ', err);

    });

  }

  deleteVersion(postId) {

    this.isLoaded = false;
    console.log('deleteVersion ', postId);
    const getVersionsURL = `${this.postAPI}/${postId}/version`;

    this.cService.serviceRequestCommon('delete', getVersionsURL).subscribe((res: any) => {

      console.log('Success deleteVersion : ', res);
      this.toastService.createMessage('success', 'Post Version deleted successfully.');
      this.removePostVersionFromList(postId);
      this.isLoaded = true;

    }, (err) => {

      console.log('Error deleteVersion : ', err);
      this.isLoaded = true;
      this.toastService.createMessage('error', 'Error deleting Post Version. Please try again.');

    });

  }

}
