<section
   class="support-tab-body"
   [attr.data-component]="'ncx/support/faq'">
   <app-collapsable
      title="NewsConnect Search"
      iconTheme="outline"
      icon="file-search">
      <section>
         <p><span class="bold">What are some helpful tips for using NewsConnect Search?</span></p>

         <p>
            <span class="bold">1.</span>
            When searching for an
            <span class="bold">exact phrase match, put your query within quotation marks</span>
            .
         </p>

         <nz-divider></nz-divider>

         <p class="bold">2. Utilize Boolean operators</p>

         <ul class="ml-8">
            <li>
               <span class="bold">AND:</span>
               All keywords must appear in your results. AND will narrow down a search.
            </li>
            <li>
               <span class="bold">OR:</span>
               Either of the keywords must appear in the results. OR will give you more results.
            </li>
            <li>
               <span class="bold">NOT:</span>
               Excludes certain keywords from your results. NOT will narrow down a search.
            </li>
         </ul>

         <nz-divider></nz-divider>

         <p class="bold">3. Filters & Sorts</p>

         <ul class="ml-8">
            <li>
               <span class="bold">Sorts</span>
               allow you to sort results by relevancy or recency:
            </li>
            <ul>
               <li>
                  <span class="bold">Relevance:</span>
                  sort results based on how closely they match your search query.
               </li>
               <li>
                  <span class="bold">Recency:</span>
                  sort results based on how recently they were
                  <span class="bold">modified</span>
                  .
               </li>
            </ul>
            <li>
               <span class="bold">Filters</span>
               allow you to narrow results by a particular:
            </li>
            <ul>
               <li>Element type (Image, Video, PDF, etc)</li>
               <li>Editorial/Standards labels</li>
               <li>R&C/Legal labels</li>
               <li>Author</li>
            </ul>
         </ul>

         <nz-divider></nz-divider>

         <p class="bold">4. Preview Pane Results</p>

         <ul class="ml-8">
            <li>
               If you enter a search keyword or phrase without hitting "Enter", you'll get a preview pane of search
               results across content types.
            </li>
         </ul>
      </section>
   </app-collapsable>

   <app-collapsable
      title="Rights, Licensing, and Legal"
      iconTheme="outline"
      icon="file-protect">
      <p class="bold">Looking for legal help or for the latest language to clear 3rd party content/UGC?</p>
      <p class="ml-8">
         Check out the Legal Toolkit
         <ng-container *ngTemplateOutlet="legalToolkit; context: { text: 'here' }"></ng-container>
         .
      </p>

      <nz-divider></nz-divider>

      <p class="bold">Need help with verifying visual content?</p>
      <p class="ml-8">
         Reach out to the Social Newsgathering team on Slack
         <ng-container *ngTemplateOutlet="slackSocialNewsgathering"></ng-container>
         .
      </p>

      <nz-divider></nz-divider>

      <p class="bold">Need help with rights and licensing?</p>
      <p class="ml-8">
         Reach out to the NBCU News Group Rights &amp; Clearances team at
         <ng-container *ngTemplateOutlet="newsArchivesRCEmail"></ng-container>
         .
      </p>

      <nz-divider></nz-divider>

      <p><span class="bold">What do the labels in NewsConnect mean?</span></p>

      <div class="ml-16">
         <p>
            The following definitions are for guidance, but you should always verify with Rights and Clearances to
            confirm whether we have the rights to content. Also,
            <span class="bold">
               please be sure to read the details in the body of each post, rather than relying solely on the labels.
            </span>
         </p>

         <ul>
            <li>
               <span class="bold">Reportable:</span>
               Information has been confirmed and can be reported.
            </li>
            <li>
               <span class="bold">Cleared:</span>
               Permission has been obtained from the copyright owner and the material can be used.
            </li>
            <li>
               <span class="bold">Copyight Risk:</span>
               Permission has not been obtained from the copyright owner. The material is usable from a
               verification/accuracy standpoint. Reach out to
               <ng-container *ngTemplateOutlet="newsArchivesRCEmail"></ng-container>
               for help with this content.
            </li>
            <li>
               <span class="bold">Do Not Use:</span>
               Editorial and/or Rights issue - Please reach out to SNG/R&C/Standards with questions.
            </li>

            <li>
               <span class="bold">Licensed:</span>
               Content licensed for All NBC News Goup Platforms/Partners.
            </li>
            <li>
               <span class="bold">Limited License:</span>
               Content has limitations on distribution.
            </li>
            <li>
               <span class="bold">Needs Licensing:</span>
               Please contact R&C.
            </li>
            <li>
               <span class="bold">Standards:</span>
               Standards has weighed in - or has been asked to weigh in. Note any added guidance.
            </li>
            <li>
               <span class="bold">Legal:</span>
               Flagged for potential Legal concern. Please reach out to (
               <ng-container *ngTemplateOutlet="newsArchivesRCEmail"></ng-container>
               ).
            </li>
            <li>
               <span class="bold">Hot:</span>
               Big breaking news sent out as a Hot Alert via email, Slack, iNEWS, and in-app notification.
            </li>
            <li>
               <span class="bold">Important:</span>
               Major development in any story.
            </li>
            <li>
               <span class="bold">Verified:</span>
               Has been verified by NBC News.
            </li>
            <li>
               <span class="bold">Great Video:</span>
               Posts with great video.
            </li>
         </ul>
      </div>
   </app-collapsable>

   <app-collapsable
      title="Angles"
      iconTheme="outline"
      icon="folder-view">
      <p><span class="bold">What is an angle?</span></p>

      <div class="ml-16">
         <p>
            Angle is a new feature in NewsConnect meant for planning, organizing, and collaboration! It lets you get
            specific about what you're working on, who you’re working with, and what content and info is relevant to
            your assignments.
         </p>
      </div>

      <nz-divider></nz-divider>

      <p><span class="bold">How do I use this?</span></p>

      <div class="ml-16">
         <p>
            You can create angles for assignments, projects, reports, or stories you and your team are working on. You
            can then add existing NewsConnect content to your angle and upload new content you want to use into your
            angle.
         </p>
         <p>
            You can also draft scripts, write notes, outlines, plans, summaries, and other pre-production material
            within the angle.
         </p>
         <p>
            Please refer to this video for additional training on Angles:
            <ng-container *ngTemplateOutlet="anglesTrainingVideo"></ng-container>
         </p>
      </div>

      <nz-divider></nz-divider>

      <p><span class="bold">Who can see my angles?</span></p>

      <p>You can make an angle public or private.</p>

      <div class="ml-16">
         <ul>
            <li>If you mark an angle as public, it and all its contents will be viewable to all users.</li>
            <ul>
               <li>
                  Public angles can be edited by creators and collaborators as well as Admin, Standards, and Sr. Editor
                  NewsConnect users.
               </li>
            </ul>
            <li>
               If you mark an angle as private, the title, linked story, and collaborators will still be visible and
               searchable to all users. However, the acutal contents of the angel itself will not be viewable to anyone
               except you and your collaborators. You can always switch a private angle to public when you are ready.
            </li>
         </ul>
      </div>

      <nz-divider></nz-divider>

      <p><span class="bold">Do story followers get notified when new angles are created?</span></p>

      <p class="ml-16">
         No, for this beta phase of the feature, we have not implemented any new notifications related to angle. You
         will continue to only get emails, based on your settings, for new posts in stories you follow, from people you
         follow, Hot, Standards, etc. Interested in angle-related notifications? Submit your ideas here:
         <ng-container *ngTemplateOutlet="angleFeedbackForm"></ng-container>
         .
      </p>

      <nz-divider></nz-divider>

      <p><span class="bold">Do collaborators get notified/emailed when I add them?</span></p>

      <p class="ml-16">
         For our beta phase, angles generate no emails, including when collaborators are added. Interested in
         angle-related notifications? Submit your ideas here:
         <ng-container *ngTemplateOutlet="angleFeedbackForm"></ng-container>
      </p>

      <nz-divider></nz-divider>

      <p><span class="bold">What are some examples of angles I could create?</span></p>

      <div class="ml-16">
         <p>
            For the story
            <ng-container
               *ngTemplateOutlet="
                  storyLink;
                  context: {
                     id: '1711435090069',
                     text: '&quot;Francis Scott Key Bridge in Baltimore Maryland has partially collapsed&quot;',
                  }
               "></ng-container>
            consider the following angles:
         </p>
         <ul>
            <li>Rescue Efforts/Missing Persons</li>
            <li>Other Bridge Notable Collapses</li>
            <li>Structurally Deficient Bridges, - ARTBA</li>
         </ul>

         <p>
            For the story
            <ng-container
               *ngTemplateOutlet="
                  storyLink;
                  context: { id: '9983', text: '&quot;Paris 2024 Olympics&quot;' }
               "></ng-container>
            consider the following angles:
         </p>

         <ul>
            <li>Profiles of Athletes - US Team</li>
            <li>Construction & Economics of the Athlete Village</li>
            <li>Olympics Sponsorships & Athlete Appearances/Endorsements</li>
         </ul>
      </div>

      <nz-divider></nz-divider>

      <p><span class="bold">Where do angles appear in NewsConnect?</span></p>

      <p class="ml-16">
         You can find angles on the new tab on story landing page and via the NewsConnect global search feature (the
         large magnifying glass). At this time, we are not showing Angles on the All Activity, Hot, and Standards pages,
         the Stories homepage, or the History tab within user profiles.
      </p>

      <nz-divider></nz-divider>

      <p>
         <span class="bold">
            I noticed since this release the post/element/summary dropdown when I publish a post is gone. How do I
            publish an Element or Summary?
         </span>
      </p>

      <div class="ml-16">
         <p>
            All Post vs Element categorization now happens automatically! If you upload any file, add any information
            into the Element Details section, or include a REM or MediaID in the description of your post, NewsConnect
            will automatically classify your post as an element!
         </p>
         <p>
            If you’re trying to classify something as an element but the file is too big to upload, put the link to the
            element in the Link to Source field and NCX will take care of the rest.
         </p>
         <p>
            We have retired the Summary feature. You can still use the summary templates in the body of your post, but
            we no longer have filters or features for manual summaries. More to come soon on automated summaries!
         </p>
      </div>

      <nz-divider></nz-divider>

      <p><span class="bold">Can I follow an angle? Or add posts to angles via email?</span></p>

      <p class="ml-16">No, currently you cannot follow angles or post to them via email.</p>

      <nz-divider></nz-divider>

      <p><span class="bold">I have ideas for more features or improvements! Who do I talk to about them?</span></p>

      <p class="ml-16">
         This feature is in Beta, which means we would LOVE to hear from you! What do you like, what’s hard to use, what
         would be great to see next. NewsConnect is a fully internally built and managed application, so we have a lot
         of flexibility to mold it to our needs. If you have any ideas, big or small, please submit them here:
         <ng-container *ngTemplateOutlet="angleFeedbackForm"></ng-container>
      </p>
   </app-collapsable>
</section>

<ng-template
   #angleFeedbackForm
   let-text="text">
   <a
      href="https://app.smartsheet.com/b/form/310add75a9d1492fac602f95f0f6228b"
      (click)="trackOutboundLink('https://app.smartsheet.com/b/form/310add75a9d1492fac602f95f0f6228b')"
      rel="noopener noreferrer"
      target="_blank">
      {{ text || 'Feedback Form' }}
   </a>
</ng-template>

<ng-template
   #anglesTrainingVideo
   let-text="text">
   <a
      href="https://nbcuni.sharepoint.com/:v:/s/news-group-newsroom-product/EV3R9aODWPtAgqZWsen1fA4Bn-mGUSoY16FbtACwdvodNg?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJTdHJlYW1XZWJBcHAiLCJyZWZlcnJhbFZpZXciOiJTaGFyZURpYWxvZy1MaW5rIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXcifX0%3D&e=w0Lr1z"
      (click)="
         trackOutboundLink(
            'https://nbcuni.sharepoint.com/:v:/s/news-group-newsroom-product/EV3R9aODWPtAgqZWsen1fA4Bn-mGUSoY16FbtACwdvodNg?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJTdHJlYW1XZWJBcHAiLCJyZWZlcnJhbFZpZXciOiJTaGFyZURpYWxvZy1MaW5rIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXcifX0%3D&e=w0Lr1z'
         )
      "
      rel="noopener noreferrer"
      target="_blank">
      {{ text || 'Angles Training Video.webm' }}
   </a>
</ng-template>

<ng-template
   #newsArchivesRCEmail
   let-text="text">
   <a
      href="mailto:NBCNewsArchivesRC@nbcuni.com"
      (click)="trackOutboundLink('mailto:NBCNewsArchivesRC@nbcuni.com')"
      target="_blank">
      {{ text || 'NBCNewsArchivesRC@nbcuni.com' }}
   </a>
</ng-template>

<ng-template
   #legalToolkit
   let-text="text">
   <a
      href="https://nbcuni.sharepoint.com/sites/newslegal"
      (click)="trackOutboundLink('https://nbcuni.sharepoint.com/sites/newslegal')"
      rel="noopener noreferrer"
      target="_blank">
      {{ text || 'Legal Toolkit' }}
   </a>
</ng-template>

<ng-template
   #slackSocialNewsgathering
   let-text="text">
   <a
      href="https://nbcuniversal.enterprise.slack.com/archives/C33G4RVKK"
      (click)="trackOutboundLink('https://nbcuniversal.enterprise.slack.com/archives/C33G4RVKK')"
      rel="noopener noreferrer"
      target="_blank">
      {{ text || '#socialnewsgathering' }}
   </a>
</ng-template>

<ng-template
   #storyLink
   let-id="id"
   let-text="text">
   <a
      href="https://newsconnect.nbcuni.com/#/ncx/landing-story/:{{ id }}"
      (click)="trackOutboundLink('/#/ncx/landing-story/:' + id)"
      rel="noopener noreferrer"
      target="_blank">
      {{ text || 'Story Link' }}
   </a>
</ng-template>
