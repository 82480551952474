<app-loader-component [isLoaded]="isLoaded"></app-loader-component>
<!-- *** Size : {{screenSize}} Width : {{windowWidth}}

**** isMobile : {{isMobile}} -->

<ng-container
   *ngIf="!viewPreview"
   class="create-post-section">
   <!-- STANDARD HEADER -->
   <div #pageHeader>
      <app-standard-header
         title="Back"
         (clickBack)="goBackToCreate()"></app-standard-header>
   </div>

   <nz-content *ngIf="!isMobile">
      <div class="post-details-metadata-flex">
         <div class="post-split">
            <app-scroll-container [footerHeight]="footerHeight">
               <ng-template bodyTemplate>
                  <section
                     class="scroll-container-content postDetailsTabBodySection"
                     #postDetailsTabBody>
                     <ng-container *ngTemplateOutlet="storyPostAngleTemplate"></ng-container>

                     <ng-container *ngTemplateOutlet="postDetailsTemplate"></ng-container>
                  </section>
               </ng-template>
            </app-scroll-container>
         </div>
         <div class="post-split">
            <div class="metadata-title-section">
               <span class="metadataTitle">Metadata</span>
            </div>
            <app-scroll-container
               [sideBarContainer]="true"
               [footerHeight]="footerHeight">
               <ng-template bodyTemplate>
                  <section
                     class="scroll-container-content metadataTabRightBodySection"
                     #metadataTabBody>
                     <ng-container *ngTemplateOutlet="metadataTemplate"></ng-container>
                  </section>
               </ng-template>
            </app-scroll-container>
         </div>
      </div>
   </nz-content>

   <nz-content *ngIf="isMobile">
      <div class="p-16">
         <ng-container *ngTemplateOutlet="storyPostAngleTemplate"></ng-container>
      </div>

      <div class="ant-tab-override ant-tab-border-override">
         <nz-tabset class="tabset">
            <nz-tab nzTitle="Main Details">
               <section
                  class="postDetailsTabHandsetBodySection"
                  #postDetailsTabBody>
                  <ng-container *ngTemplateOutlet="postDetailsTemplate"></ng-container>
               </section>
            </nz-tab>
            <nz-tab nzTitle="Metadata">
               <section class="post-Title-View-Section">
                  <ng-container *ngTemplateOutlet="postTitleTemplate"></ng-container>
               </section>
               <section
                  class="metadataTabHandsetSection"
                  #metadataTabBody>
                  <section class="metadataTabHandsetBodySection">
                     <ng-container *ngTemplateOutlet="metadataTemplate"></ng-container>
                  </section>
               </section>
            </nz-tab>
         </nz-tabset>
      </div>
   </nz-content>

   <div
      class="footer-section"
      #footerElement>
      <nz-footer class="footer-flex">
         <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
      </nz-footer>
   </div>

   <app-google-docs
      [isVisible]="isVisible"
      (docLinkUpdated)="getDocLink($event)"></app-google-docs>
   <app-embedly
      [isEmbedlyVisible]="isEmbedlyVisible"
      (embedlyUpdated)="getEmbedlyData($event)"></app-embedly>
</ng-container>

<nz-layout
   class="preview-post-section"
   *ngIf="viewPreview">
   <!-- STANDARD HEADER -->
   <app-standard-header
      title="Preview Content"
      (clickBack)="goBackToCreate()">
      <ng-template header-right>
         <div class="header">
            <button
               nz-button
               nzType="text"
               nzShape="circle"
               nzSize="large"
               class="toggle-icon"
               (click)="toggleSider()">
               <i
                  nz-icon
                  nzType="setting"
                  nzTheme="outline"></i>
            </button>
         </div>
      </ng-template>
   </app-standard-header>

   <nz-layout>
      <nz-content>
         <app-scroll-container [footerHeight]="footerHeight">
            <ng-template bodyTemplate>
               <section
                  class="scroll-container-content postDetailsPreviewBodySection"
                  #postDetailsTabBody>
                  <ng-container *ngTemplateOutlet="postDetailsPreviewTemplate"></ng-container>
               </section>
            </ng-template>
         </app-scroll-container>
      </nz-content>

      <nz-sider
         nzCollapsible
         [nzWidth]="isMobile ? windowWidth : '300px'"
         nzBreakpoint="lg"
         [(nzCollapsed)]="previewSiderIsCollapsed"
         [nzTrigger]="null"
         nzCollapsedWidth="0"
         class="metadata-sider">
         <ng-container *ngTemplateOutlet="postDetailsPreviewSiderTemplate"></ng-container>
      </nz-sider>
   </nz-layout>

   <div
      class="footer-section"
      #footerElement>
      <nz-footer class="footer-flex">
         <ng-container *ngTemplateOutlet="footerPreviewTemplate"></ng-container>
      </nz-footer>
   </div>
</nz-layout>

<ng-template #storyPostAngleTemplate>
   <app-content-types
      [singleContentView]="postId ? true : false"
      contentType="Post"></app-content-types>
</ng-template>

<ng-template #postDetailsTemplate>
   <div class="">
      <div class="postTitleAndContent">
         <div class="postTitleSection">
            <div class="postTitle">
               <input
                  required
                  #title="ngModel"
                  #searchPostTitleText
                  placeholder="Give your post a title"
                  class="postTitleText"
                  nz-input
                  [(ngModel)]="postDetails.postTitle"
                  (input)="onInput($event.target?.value)"
                  [nzAutocomplete]="auto"
                  (ngModelChange)="titleChange()" />
               <nz-autocomplete
                  nzBackfill
                  #auto></nz-autocomplete>

               <div
                  *ngIf="title.invalid && (title.dirty || title.touched)"
                  class="alert alert-danger">
                  <div
                     class="errorMessage"
                     *ngIf="title.errors.required">
                     <span>Post Title is required</span>
                  </div>
               </div>
            </div>

            <!--Dummy span to have a click event - Google Doc and Embedly Link works after click -->
            <span
               (click)="dummyGoogleDocEmbedyLinkClick()"
               id="dummy-Google-Doc-Embedy-Link"></span>
            <div class="rtEditor">
               <app-rteditor
                  [saveType]="''"
                  [rteValue]="postDetails.postContent"
                  rteHeight="212"
                  [input]="'postDesc'"
                  [isEmitted]="isEmitted"
                  [googleDocs]="googleDocs"
                  [summary]="true"
                  [isSavedAsDraft]="isSavedAsDraft"
                  (rteUpdated)="getRTEData($event)"
                  (rtePasteContent)="getRTEData($event)"
                  (gFlag)="checkFlag($event)"
                  (rteContentChanged)="rteContentChanged($event)"
                  (input)="rteEdit($event)"
                  (rteEdited)="rteDescEdited($event)"></app-rteditor>
            </div>
         </div>
      </div>

      <div class="linktoAngleOrStory">
         <div class="title">
            <span class="mandatory">*</span>
            Add to Angle or Story
         </div>

         <app-linked-angles
            (addedAngles)="attachAnglesToPost($event)"
            [postRelatedAngles]="postDetails.relatedAngles"
            [highlightMandatory]="highlightMandatoryStoryOrAngle"></app-linked-angles>

         <div class="mb-16"></div>

         <app-linked-stories
            (addedStories)="attachStoryToPost($event)"
            [postRelatedStories]="postDetails.relatedStories"
            [isPinnedOnTop]="postDetails.isPinnedOnTop"
            [tooltip]="storyDetailsTooltip"
            [showMandatoryAsterisk]="showStoryMandatory"
            [highlightMandatoryText]="highlightMandatoryStoryOrAngle"
            [mandatoryMsg]="'Angle/Story is required'"></app-linked-stories>
      </div>

      <div
         class="rtStandardGuidanceEditor"
         *ngIf="postDetails?.editorialStatus?.includes(standardsLabel)">
         <div
            id="standardGuidance"
            [ngClass]="{ 'standard-disable': isStandards }">
            <div nz-row>
               <div
                  nz-col
                  class="standardGuidanceTitle">
                  <span>Standards Guidance</span>
               </div>
            </div>
            <div class="standardGuidanceEditor">
               <app-rteditor
                  [rteHeight]="212"
                  [input]="'postStandardGuidance'"
                  [isEmitted]="isEmitted"
                  [rteValue]="postDetails.standardsGuidance?.notes"
                  (rteUpdated)="getRTEStandardGuidanceData($event)"
                  (rtePasteContent)="getRTEStandardGuidanceData($event)"
                  (rteContentChanged)="rteStandardGuidanceContentChanged($event)"
                  (rteEdited)="rteEdited($event)"></app-rteditor>
            </div>
         </div>
      </div>

      <div
         class="reportableSection"
         *ngIf="postDetails?.editorialStatus?.includes(reportableLabel)">
         <app-reportable-new
            (postEdited)="rteEdited($event)"
            [postReportableContent]="this.postDetails.reportableApprover"
            (reportableDataUpdated)="getReportableData($event)"></app-reportable-new>
      </div>

      <div
         class="limitedLicenseSection"
         *ngIf="postDetails?.postLegal?.includes(limitedLicenseLabel)">
         <app-limited-license-new
            [limitLicenseContent]="postDetails.limitedLicenseApprover"
            (postEdited)="rteEdited($event)"
            (limitedLicenseDataUpdated)="getLimitedLicenseData($event)"></app-limited-license-new>
      </div>

      <div class="elementsSection">
         <app-attachments-new
            [attachments]="postDetails.postAttachments"
            [attachmentDetails]="postDetails.attachmentDetails"
            [rteValue]="postDetails.postContent"
            [postTitle]="postDetails.postTitle"
            [isAttachmentDisabled]="isAttachmentDisabled"
            (attachmentDetailsUpdated)="getAttachmentsDetails($event)"
            (attachmentsUpdated)="getAttachmentsData($event)"
            (eventUpdater)="uploadReq($event)"
            (materialsUpdated)="getMaterialsDetails($event)"
            [savedpostId]="postId"
            (postEdited)="rteEdited($event)"></app-attachments-new>
      </div>

      <div
         *ngIf="canSendEmailUpdate"
         class="dont-send-email">
         <label
            nz-checkbox
            [(ngModel)]="sendEmailUpdate"
            (ngModelChange)="toggleEmailCheckbox()">
            Do not Send Email Update for this Edit
         </label>
      </div>
   </div>
</ng-template>

<ng-template #postTitleTemplate>
   <div class="post-title-metadata-flex">
      <div class="post-title-metadata">{{ postDetails.postTitle }}</div>
   </div>
</ng-template>

<!-- RIGHT-HAND SIDEBAR METADATA (TAGS,LABELS,TOPICS) -->
<ng-template #metadataTemplate>
   <app-posts-metadata
      [viewMode]="false"
      [postDetails]="postDetails"
      [filterList]="availableLabels"
      (onLabelsSelected)="toggleCheckbox($event.labels, $event.selected, $event.list)"
      (onTagsSelected)="updateTags($event)"
      (onTopicsSelected)="attachTopicsToPost($event)"
      (postEdited)="rteEdited($event)"
      (onGenerateTags)="toggleGenerateFlag($event)"></app-posts-metadata>
</ng-template>

<ng-template #footerTemplate>
   <button
      nz-button
      nzType="default"
      [nzShape]="isMobile ? 'circle' : ''"
      nzDanger
      class="footer-btn"
      [ngClass]="isMobile ? 'left-btn' : ''"
      (click)="cancel(false)">
      <span
         nz-icon
         nzType="close"
         nzTheme="outline"></span>
      <span *ngIf="!isMobile">Cancel</span>
   </button>

   <!-- <button *ngIf="isMobile" nz-button nzType="default" nzShape="circle" class="footer-btn"
    (click)="publishAs('Draft',  'RESET')">
    <span nz-icon nzType="save" nzTheme="outline"></span></button>
  <button *ngIf="isMobile" nz-button nzType="default" nzShape="circle" class="footer-btn"
    (click)="showPreview()">
    <span nz-icon nzType="expand" nzTheme="outline"></span></button> -->

   <button
      nz-button
      nzType="default"
      class="footer-btn right-btn default-btn"
      [nzShape]="isMobile ? 'circle' : 'round'"
      (click)="saveDraft()">
      <span
         nz-icon
         nzType="save"
         nzTheme="outline"></span>
      <span *ngIf="!isMobile">Save Draft</span>
   </button>
   <button
      nz-button
      nzType="default"
      class="footer-btn default-btn"
      [nzShape]="isMobile ? 'circle' : 'round'"
      (click)="showPreview()">
      <span
         nz-icon
         nzType="expand"
         nzTheme="outline"></span>
      <span *ngIf="!isMobile">Preview</span>
   </button>
   <button
      nz-button
      nzType="primary"
      class="footer-btn publish-btn"
      (click)="savePost()">
      <span
         nz-icon
         nzType="send"
         nzTheme="outline"></span>
      Publish
   </button>

   <!-- <div nz-row class="footerBtns">
      <div nz-col [nzXs]="12" *ngIf="isMobile">
          <div nz-row class="footerLeftBtns">
              <button nz-button nzType="default" nzShape="circle" nzDanger class="footerBtn" (click)="redirectTo()">
                  <span nz-icon nzType="delete" nzTheme="outline"></span></button>
              <button nz-button nzType="default" nzShape="circle" class="footerBtn"
                  (click)="publishAs('Draft',  'RESET')">
                  <span nz-icon nzType="save" nzTheme="outline"></span> </button>
              <button nz-button nzType="default" nzShape="circle" class="footerBtn" (click)="showPreview()">
                  <span nz-icon nzType="expand" nzTheme="outline"></span></button>
          </div>
      </div>
      <div nz-col [nzXs]="isMobile ? '12' : '24'">
          <div nz-row class="footerRightBtns">
              <button *ngIf="!isMobile" nz-button nzType="default" nzDanger class="footerBtn"
                  (click)="redirectTo()">
                  <span nz-icon nzType="delete" nzTheme="outline"></span> Cancel</button>
              <button *ngIf="!isMobile" nz-button nzType="default" class="footerBtn"
                  (click)="publishAs('Draft',  'RESET')"><span nz-icon nzType="save" nzTheme="outline"></span>Save
                  Draft</button>
              <button *ngIf="!isMobile" nz-button nzType="default" class="footerBtn"
                  (click)="showPreview()"><span nz-icon nzType="expand" nzTheme="outline"></span>Preview</button>
              <button nz-button nzType="primary" class="footerBtn" (click)="publishAs('Post')"><span nz-icon
                      nzType="send" nzTheme="outline"></span>Publish</button>
          </div>
      </div>
  </div> -->
</ng-template>

<ng-template #postDetailsPreviewTemplate>
   <section #postDetailsTabBody>
      <div class="post-preview">
         <div class="postTitleDetails">
            <a href="#">{{ postDetails.postTitle }}</a>
         </div>

         <div
            *ngIf="postDetails?.editorialStatus?.includes(standardsLabel)"
            class="mt-16">
            <app-standard-guidance-view [postDetails]="postDetails"></app-standard-guidance-view>
         </div>

         <div
            class="postDescription fr-element fr-view"
            #previewPostContent
            id="previewContent">
            <span [innerHtml]="postDetails?.postContent | safeHtml"></span>
         </div>

         <!-- Reportable Approver -->
         <div
            class="section mt-16"
            *ngIf="postDetails?.editorialStatus?.includes(reportableLabel)">
            <app-reportable-approver-view [postDetails]="postDetails"></app-reportable-approver-view>
         </div>

         <!--License Limitations-->
         <div
            *ngIf="this.postDetails?.postLegal?.includes(limitedLicenseLabel)"
            class="section mt-16">
            <app-license-limitations-view [postDetails]="postDetails"></app-license-limitations-view>
         </div>

         <div
            *ngIf="postDetails.postAttachments"
            class="postAttachments mt-16">
            <app-view-attachments
               [postAttachments]="postDetails.postAttachments"
               [showAllAttachments]="true"></app-view-attachments>
         </div>

         <div
            *ngIf="
               postDetails &&
               postDetails.attachmentDetails &&
               (postDetails.attachmentDetails.linkToSource !== '' ||
                  postDetails.attachmentDetails.creditName !== '' ||
                  this.postDetails.attachmentDetails.captureDate?.toString() !== '' ||
                  (postDetails.attachmentDetails.clearanceStatus != null &&
                     postDetails.attachmentDetails.clearanceStatus !== ''))
            "
            class="section mt-16">
            <app-element-details-view [postDetails]="postDetails"></app-element-details-view>
         </div>

         <!-- Linked Angles -->
         <div
            class="section mt-8"
            *ngIf="postDetails.relatedAngles?.length > 0">
            <app-linked-angles-view [relatedAngles]="postDetails.relatedAngles"></app-linked-angles-view>
         </div>

         <!-- Linked Stories -->
         <div
            class="section mt-8"
            *ngIf="postDetails.relatedStories?.length > 0">
            <app-linked-stories-view [relatedStoryTitles]="postDetails.relatedStories"></app-linked-stories-view>
         </div>

         <div class="postLabels">
            <app-post-labels
               [editorial]="postDetails.editorialStatus"
               [legal]="postDetails.postLegal"></app-post-labels>
         </div>

         <div class="section mt-16"></div>

         <span style="float: right">
            <app-info-attachments
               class="mr-24"
               [total]="postDetails?.postAttachments?.length || 0"></app-info-attachments>

            <app-info-comments [total]="postDetails?.repliesCount || 0"></app-info-comments>
         </span>
      </div>
   </section>
</ng-template>

<ng-template #postDetailsPreviewSiderTemplate>
   <div
      class="metadata-title-section"
      *ngIf="!previewSiderIsCollapsed">
      <span class="metadataTitle">Metadata</span>
      <i
         nz-icon
         nzType="close"
         nzTheme="outline"
         (click)="toggleSider()"
         class="metadataClose"></i>
   </div>
   <app-scroll-container [footerHeight]="footerHeight">
      <ng-template bodyTemplate>
         <section
            *ngIf="!previewSiderIsCollapsed"
            class="scroll-container-content metadataPreviewSection"
            #metadataTabBody>
            <ng-container *ngTemplateOutlet="metadataTemplate"></ng-container>
         </section>
      </ng-template>
   </app-scroll-container>
</ng-template>

<ng-template #footerPreviewTemplate>
   <button
      nz-button
      nzType="default"
      [nzShape]="isMobile ? 'circle' : ''"
      nzDanger
      class="footer-btn left-btn"
      (click)="redirectToEdit()">
      <span
         nz-icon
         nzType="close"
         nzTheme="outline"></span>
      <span *ngIf="!isMobile">Cancel</span>
   </button>
   <button
      nz-button
      nzType="primary"
      class="footer-btn right-btn"
      [disabled]="!enablePreviewPublish"
      (click)="savePost()">
      <span
         nz-icon
         nzType="send"
         nzTheme="outline"></span>
      Publish
   </button>
</ng-template>
